/**
 * Created by glenne on 6/17/2017.
 */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RegattaList from './RegattaList';
import LoadingIndicator from './shared/LoadingIndicator';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database'; // eslint-disable-line import/no-unassigned-import
import { ResultSummary } from './shared/CrewTimerTypes';

interface ResultsPageProps {
  onRegattaSelected: (regatta: string, regattaInfo: any) => void;
}
const ResultsPage = (props: ResultsPageProps) => {
  const [regattaList, setRegattaList] = useState<ResultSummary[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    /* Create reference to results in Firebase Database */
    const regattaListRef = firebase.database().ref('rsummary');
    regattaListRef.on('value', (snapshot) => {
      /* Update React state when results change */
      const summaries = snapshot.val();
      Object.keys(summaries).forEach((key) => {
        if (!summaries[key].Public) {
          delete summaries[key];
        }
      });

      setRegattaList(Object.values(summaries));
    });
    return () => {
      if (regattaListRef) {
        regattaListRef.off();
      }
    };
  }, []);

  const onRegattaSelected = (regatta: string, regattaInfo: any) => {
    if (props.onRegattaSelected) {
      props.onRegattaSelected(regatta, regattaInfo);
    }
    navigate('/regatta/' + regatta);
  };

  if (regattaList) {
    return (
      <div className="RegattaList">
        <RegattaList onRegattaSelected={onRegattaSelected} regattaList={regattaList} />
      </div>
    );
  } else {
    return <LoadingIndicator />;
  }
};

export default ResultsPage;
